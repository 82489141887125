import overlayMenu from "../components/overlay-menu.vue";

export default {
    init() {
        console.log('common');
    },
    finalize() {

        function animate_all() {

            const s1 = new TimelineMax();
            s1
                .set(['.anime-0', '.anime-1', '.anime-2', '.anime-3', '.anime-4', '.anime-5', '.anime-6', '.anime-7', '.anime-8', '.anime-9'], {
                    autoAlpha: 0,
                })
                .to(['.anime-0'], 1, {
                    autoAlpha: 1,
                }, .75)
                .to(['.anime-1'], 1, {
                    autoAlpha: 1,
                }, 1)
                .to(['.anime-2'], 1, {
                    autoAlpha: 1,
                }, 2)
                .to(['.anime-3'], 1, {
                    autoAlpha: 1,
                }, 3)
                .to(['.anime-4'], 1, {
                    autoAlpha: 1,
                }, 4)
                .to(['.anime-5'], 1, {
                    autoAlpha: 1,
                }, 5)
                .to(['.anime-6'], 1, {
                    autoAlpha: 1,
                }, 6)
                .to(['.anime-7'], 1, {
                    autoAlpha: 1,
                }, 7)
                .to(['.anime-8'], 1, {
                    autoAlpha: 1,
                }, 8)
                .to(['.anime-9'], 1, {
                    autoAlpha: 1,
                }, 9)

            const s2 = new TimelineMax();
            var staggerSet = ['.anime-stgr-0', '.anime-stgr-1', '.anime-stgr-2', '.anime-stgr-3', '.anime-stgr-4', '.anime-stgr-5', '.anime-stgr-6', '.anime-stgr-7', '.anime-stgr-8', '.anime-stgr-9']
            s2
                .set(staggerSet, {
                    autoAlpha: 0,
                })
                .delay(2)
                .staggerTo(staggerSet, .5, {
                    autoAlpha: 1,
                }, .25)
        }

        animate_all();

        function animate_events() {
            const s2 = new TimelineMax();
            s2
                .set('.event-container', {
                    autoAlpha: 0,
                    y: 20
                })
                .delay(1)
                .staggerTo('.event-container', 1, {
                    autoAlpha: 1,
                    y: 0,
                    ease: Power2.easeIn
                }, .5)
        }

        animate_events();

        var loadTime;

        function loader() {
            loadTime = setTimeout(showPage, 300);
        }

        function showPage() {
            document.getElementById("loader").style.display = "none";
            document.getElementById("main-content").style.display = "block";
        }

        loader();

        //Mobile Overlay Menu Vue component
        new Vue({
            components: {
                overlayMenu
            },

            el: "#mobile-nav",

            data: {
                menu: {},
                hide: true,
                selected: -1,
            },

            mounted() {
                axios.get("/wp-json/cpl/v1/menu").then(response => {
                    this.menu = response.data;
                });
            },

            methods: {
                selectedItem(id) {
                    this.selected = id;
                },

                openMenu() {
                    this.hide = false;
                    this.animateOpenMenu();
                },

                closeMenu() {
                    this.hide = true;
                    this.animateCloseMenu();
                },

                animateOpenMenu() {
                    const s1 = new TimelineMax();
                    const overlay = this.$el.children[1];
                    s1.set(
                        overlay,
                        {
                            autoAlpha: 0,
                            height: "0",
                        }
                    ).to(overlay, 1, {
                            autoAlpha: 1,
                            height: "100%",
                            ease: Back.easeOut.config(1.4)
                        },
                        0)
                },

                animateCloseMenu() {
                    const s1 = new TimelineMax();
                    const overlay = this.$el.children[1];
                    s1.set(
                        overlay,
                        {
                            autoAlpha: 1,
                            height: "100%",
                        }
                    ).to(overlay, 1, {
                            autoAlpha: 0,
                            height: "0",
                            ease: Back.easeOut.config(1.4)
                        },
                        0)
                }
            }

        });


        (function ($) {

            $('.cpl-arrow-btn').hover(function () {
                const s1 = new TimelineMax();
                s1
                    .to($('span', this), .25, {
                        x: 5,
                    }, 0)

            }, function () {
                const s1 = new TimelineMax();
                s1
                    .to($('span', this), .1, {
                        x: 0,
                    }, 0)
            });

            $(function () {
                var url = window.location;
                $('#menu-primary a').filter(function () {
                    return this.href == url;
                }).addClass('active');
            });

            $(".owl-carousel.testimonial-carousel").owlCarousel({
                loop: true,
                nav: false,
                dots: true,
                navText: [
                    '<i class="fal fa-2x fa-long-arrow-left"></i>',
                    '<i class="fal fa-2x fa-long-arrow-right"></i>'
                ],
                items: 1
            });


        })(jQuery);

    },
};
