<template>

    <div id="overlay" class="d-lg-none d-xl none" v-show="hide === false">
        <div class="container-fluid p-0">
            <div class="row" id="menu">
                <div class="col-2"></div>
                <div class="col-8" id="menu-list">
                    <ul class="py-3">
                        <li v-for="item in menu"
                            @mouseover="$emit('update-menu', item.ID )"
                            @mouseleave="$emit('update-menu', -1)"
                            :class="{'selected' : item.selected }">
                            <a :href="item.url"> {{item.title}}
                                <span v-if="selected === item.ID">
                                    <i class="fal fa-arrow-right"></i>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-2 text-right">
                    <p class="close" @click="$emit('close-menu', true)"><i class="fal fa-times-hexagon fa-lg"></i></p>
                </div>
            </div>


        </div>
        <div class="row pt-3" id="footer">
            <div class="col-12 text-center">
                <a href="/">
                    <img src="/wp-content/themes/Capabl/assets/images/whitney-logo-light-v2.png"
                         alt="Whitney Sleep Center" width="195px">
                </a>
                <p class="m-0"><a href="mailto:masonw@whitneysleepcenter.com">masonw@whitneysleepcenter.com</a></p>
            </div>
        </div>
    </div>

</template>

<script>
    export default {

        props: ["menu", "selected", "hide"],

        created() {
        },

        mounted() {},

        methods: {

        },

    }
</script>

<style lang="scss">
    @import "./src/styles/_global-vars.scss";

    #overlay {
        opacity: 0;
        background-color: $main-gray;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3000;
        overflow: scroll;

        .close {
            float: none;
            padding: 1.5rem 2rem 0 0;
            cursor: pointer;
        }

        #menu {
            background-color: $primary;
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;

            li {
                text-align: center;
                font-size: 1.5rem;
                padding: .5rem 0;

                a {
                    color: $body-color;
                    transition: all 500ms;

                    &:hover {
                        color: #828079;
                    }
                }

            }
        }

        #footer {
            background-color: $main-gray;

            a {
                color: $footer-font-color;
            }
        }

    }


</style>