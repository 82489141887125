export default {
    init() {
        console.log('Services');
    },
    finalize() {

        function animate_services() {
            const s2 = new TimelineMax();
            s2
                .set('.service-card', {
                    autoAlpha: 0,
                    y: 20
                })
                .delay(1)
                .staggerTo('.service-card', 1, {
                    autoAlpha: 1,
                    y: 0,
                    ease: Power2.easeIn
                }, .5)
        }

        animate_services();

    },
};
